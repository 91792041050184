define("discourse/plugins/discourse-mlm-daily-summary/discourse/initializers/mlm-daily-summary", ["exports", "discourse-common/utils/decorators", "discourse/controllers/preferences/emails", "discourse/controllers/user"], function (_exports, _decorators, _emails, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'mlm_daily_summary',
    initialize(container) {
      _emails.default.reopen(dt7948.p({
        userMLMDailySummaryEnabled() {
          const user = this.get("model");
          return user.get("custom_fields.user_mlm_daily_summary_enabled");
        },
        _setUserMLMDailySummary() {
          var attrNames = this.get("saveAttrNames");
          attrNames.push('custom_fields');
          this.set("saveAttrNames", attrNames);
          const user = this.get("model");
          const userMLMDailySummaryEnabled = user.custom_fields.user_mlm_daily_summary_enabled;
          user.set("custom_fields.user_mlm_daily_summary_enabled", userMLMDailySummaryEnabled);
        }
      }, [["method", "_setUserMLMDailySummary", [(0, _decorators.observes)("model.custom_fields.user_mlm_daily_summary_enabled")]]]));
    }
  };
});